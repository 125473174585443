
import Head from "@/components/Head.vue"
import Article from "@/components/Article.vue"
// import Footer from "@/components/Footer.vue";

export default {
  name: "Kontakty",
  components: {
    Head,
    Article,
    // Footer,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to: any, from: AnalyserNode) {
        document.title = "MasterGames | Contact";
      },
    },
  },
};
