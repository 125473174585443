
import { defineComponent } from "vue";

export default defineComponent({
  name: "Head",
  props: ["headers"],
  data() {
  return {
    header: this.headers,
      }
}
});
