import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Head = _resolveComponent("Head")!
  const _component_Article = _resolveComponent("Article")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Head, { headers: "Contact" }),
    _createVNode(_component_Article, {
      headers: "联系方式",
      texts: "QQ群: 716090755"
    })
  ], 64))
}